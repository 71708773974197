/***** Custom Select ******/

/* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.01em;
  font-weight: 600;
  margin-bottom: 48px;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  padding: 24px;
  background-color: #ffffff;
  background-image: url('/assets/icon-chevron.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 24px) center;
  box-shadow: 0px 10px 48px -4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}

.select-selected:before {
  display: block;
  content: 'Customer GMV';
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  margin-bottom: 20px;
  cursor: pointer;
}

.select-items div:last-child {
  margin-bottom: 0;
}

/* Style items (options): */
.select-items {
  position: absolute;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 10px 48px -4px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  top: calc(100% + 20px);
  left: 0;
  right: 0;
  z-index: 99;
  font-size: 22px;
  line-height: 28px;
  font-weight: 300;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover {
  color: var(--color-pink);
}

.same-as-selected {
  color: black;
  font-weight: 600;
}

@media screen and (max-width: 639px) {
  .custom-select {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .select-selected:before {
    font-size: 16px;
    line-height: 20px;
  }
  .select-items {
    font-size: 18px;
    line-height: 24px;
  }
}