/*****************************
 * TEXT
 *****************************/

.text--white {
  color: white;
}

.text--grey {
  color: var(--color-grey);
}

.text--light-grey {
  color: var(--color-light-grey);
}

.text--pink {
  color: var(--color-pink);
}

.text--small {
  font-size: 18px;
  line-height: 28px;
}

/*****************************
 * BACKGROUNDS
 *****************************/

.bg--black {
  background-color: black;
}

/*****************************
 * BUTTONS
 *****************************/

.button {
  display: inline-block;
  width: fit-content;
  height: 52px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  text-decoration: none;
  background: var(--color-pink);
  cursor: pointer;
  color: white;
}

.button--nav {
  display: none;
  width: 40px;
  height: 40px;
  padding: 6px;
  background-image: url('/assets/icon-nav.svg');
  background-position: center;
  background-size: 16px auto;
  background-repeat: no-repeat;
}

.button--nav-open {
  background-image: url('/assets/icon-close.svg');
}

.button--centered {
  display: block;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 639px) {
  .button--nav {
    display: block;
  }
  .button--centered {
    width: 100%;
  }
}

.text-link {
  color: var(--color-pink);
  text-decoration: none;
}
