/*****************************
 * VARIABLES
 *****************************/

:root {
  --color-beige: #f9f7ee;
  --color-pink: #e51ee5;
  // --color-pink: #c11bc1;
  --color-light-grey: #66777a;
  --color-grey: #535a5e;
}
