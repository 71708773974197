.footer {
  background: var(--color-pink);
  padding: 20px;

  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
