/*****************************
 * SECTIONS
 *****************************/

.section--small {
  padding: 64px 0;
}

.section--large {
  padding: 178px 0;
}

.section--background {
  background-image: url('../assets/icon-experiences-masked.svg'),
    url('../assets/icon-relationships-masked.svg');
  background-position: left 200px, right 90%;
  background-repeat: no-repeat;
}

/* Mobile */
@media screen and (max-width: 639px) {

  .section--small {
    padding: 32px 0;
  }

  .section--large {
    padding: 92px 0;
  }

  .section--background {
    background-image: url(../assets/icon-experiences-white.svg),
      url(../assets/icon-relationships-white.svg);
    background-position: center 56px, center calc(100% - 32px);
    background-repeat: no-repeat;
    padding: 180px 0;
  }

}



/*****************************
 * CONTAINERS
 *****************************/

.container {
  max-width: 1154px;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;
}

.container--large {
  max-width: 1440px;
}



/*****************************
 * CONTENT BLOCKS
 *****************************/

.container {
  max-width: 1154px;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;
}

.container--large {
  max-width: 1440px;
}

.content-block {
  display: flex;
  flex-direction: column;
}

.content-block p {
  max-width: 640px;
  margin-top: 36px;
  margin-bottom: 36px;
}

.content-block--center {
  align-content: center;
  text-align: center;
}

.content-block--center h2 {
  max-width: 600px;
  margin: 0 auto;
}

.content-block--center p {
  margin-left: auto;
  margin-right: auto;
}

.content-block--center .button {
  align-self: center;
  margin-top: 36px;
}

/* Mobile */
@media screen and (max-width: 639px) {

  .container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .content-block p {
    margin-top: 24px;
    margin-bottom: 24px;
  }

}