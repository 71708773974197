.form {
  width: 480px;
  margin: 92px auto 46px;
  padding: 56px;
  background: #ffffff;
  box-shadow: 0px 10px 48px -4px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.form-group {
  display: flex;
  justify-content: space-between;
}

.form-group > * {
  width: calc(50% - 8px);
}

.form__submit {
  box-shadow: rgb(0 0 0 / 4%) 1px 1px 3px;
  transition: border-radius 200ms ease 0s;
  height: unset;
  padding: 10px 12px;
  min-height: 56px;
  border-radius: 4px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  background-color: rgb(193, 27, 193);
  border: 0px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-family: inherit;
  font-style: normal;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  outline: none;
}

@media screen and (max-width: 639px) {
  .form {
    margin: 24px auto 0;
    padding: 36px 24px;
    border-radius: 10px;
    width: 100%;
  }
}
