.terms .container {
  display: flex;
}

.terms__content {
  padding-right: 130px;

  h1,h2,h3,p,ul,ol {
    color: white;
  }

  h1 {
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 80px;
  }

  h3 {
    margin-top: 40px;
    font-size: 28px;
    line-height: 36px;
  }

  p {
    margin-top: 16px;
  }

  p, ol {
    font-size: 16px;
    line-height: 20px;
  }

  p.intro {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  ol {
    list-style-type: lower-alpha;
    margin-left: 18px;

    li {
      margin: 8px 0;
    }
  }

  a {
    color: #fff;
  }
}

.terms__updated {
  margin-bottom: 64px;
}

.terms__nav {
  padding: 36px 24px 24px;
  background: #FFFFFF;
  box-shadow: 0px 10px 48px -4px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  min-width: 330px;
  height: fit-content;
  position: sticky;
  top: 24px;

  p {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;

    img {
      display: none;
    }
  }

  ol {
    font-size: 16px;
    line-height: 18px;
    margin-left: 16px;
    margin-top: 16px;
  }

  li {
    margin: 16px 0;
  }
}

@media screen and (max-width: 1159px) {
  .terms {
    padding-top: 0;
  }

  .terms .container {
    flex-direction: column;
    padding: 0;
  }

  .terms__content {
    padding-right: 0;
    order: 2;
    padding: 0 32px;

    h3 {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .terms__nav {
    top: 0;
    border-radius: 0;
    padding: 16px 32px;
    margin-bottom: 32px;

    p {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        display: block;
      }
    }

    p.open img {
      transform: rotate(180deg);
    }

    ol {
      display: none;
    }
    ol.open {
      display: block;
    }
  }
}
