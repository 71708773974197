.offers {
  display: flex;
  flex-direction: column;
}

.offer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 128px;
  width: 60%;
}

.offer:nth-child(even) {
  align-self: flex-end;
}

@media screen and (max-width: 767px) {
  .offer {
    width: 100%;
    margin-top: 64px;
  }
}

.offer__icon {
  display: inline-block;
  width: fit-content;
  margin-bottom: 32px;
}

.offer__name {
  margin-bottom: 32px;
  font-size: 44px;
  line-height: 52px;
  letter-spacing: -0.01em;
  font-weight: bold;
}

.offer__description {
}



/* Mobile */
@media screen and (max-width: 767px) {

.offer__name {
  font-size: 22px;
  line-height: 28px;
}

}