.nav {
  display: flex;
  align-items: center;
}

.nav__item {
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  text-decoration: none;
  color: black;
  margin-right: 64px;
}

@media screen and (max-width: 767px) {
  .nav__item {
    margin-right: 32px;
  }
}

@media screen and (max-width: 639px) {
  .nav {
    display: none;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid var(--color-pink);
    border-bottom: 1px solid var(--color-pink);
    padding: 36px 0;
    margin-top: 36px;
  }

  .nav--open {
    display: flex;
  }

  .nav__item {
    margin-bottom: 36px;
    margin-right: 0;
  }
}