.plans {
  display: flex;
  justify-content: space-between;
}

.plan {
  width: calc(50% - 28px);
  padding: 56px;
  background: #ffffff;
  box-shadow: 0px 10px 48px -4px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.plan__name {
  font-size: 28px;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.plan__description {
  margin: 28px 0;
}

.plan__cost {
  font-size: 22px;
  line-height: 28px;
  margin: 8px 0;
}

.plan__amount {
  font-size: 54px;
  line-height: 64px;
  font-weight: bold;
  letter-spacing: -0.02em;
}

.plan__details {
  font-size: 18px;
  line-height: 36px;
  list-style-type: none;
  margin-left: 22px;
}

.plan__details li {
  list-style-image: url('../assets/icon-pink-check.png');
}

.plan__details li.green-check {
  list-style-image: url('../assets/icon-green-check.png');
}

@media screen and (max-width: 767px) {
  .plans {
    flex-direction: column;
  }
  .plan {
    width: 100%;
    padding: 36px 16px;
    border-radius: 8px;
  }
  .plan:first-child {
    margin-bottom: 24px;
  }
}

/* Mobile */
@media screen and (max-width: 639px) {
  .plan__name {
    font-size: 22px;
    line-height: 28px;
  }

  .plan__amount {
    font-size: 28px;
    line-height: 36px;
  }

  .plan__details {
    font-size: 16px;
    line-height: 32px;
  }
}
