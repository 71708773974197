.legal {
  &__intro {
    color: #ffffff;
    text-align: center;

    h1 {
      margin: 30px 0;
    }
  }

  &__content {
    display: flex;
    margin: 150px 0;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 50px;
    }
  }

  &__box {
    width: calc(33% - 28px);
    background: #ffffff;
    box-shadow: 0px 10px 48px -4px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    padding: 40px 26px;
    position: relative;

    @media screen and (max-width: 767px) {
      margin: 20px 0;
      width: 100%;
    }
  }

  &__name {
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 2px;
  }

  &__description {
    font-size: 18px;
    line-height: 28px;
    margin: 10px 0 50px;
  }

  &__link {
    background: #e51ee5;
    border-radius: 10px;
    padding: 12px;
    position: absolute;
    right: 35px;
    bottom: 25px;

    .legal__icon {
      margin-bottom: 0;
    }
  }

  &__icon {
    display: inline-block;
    width: fit-content;
    margin-bottom: 5px;
  }
}
