.header {
  padding: 40px 0;
}

.header__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 639px) {
  .header {
    padding: 24px 0;
  }
  .header__logo {
    width: auto;
    height: 28px;
  }
}