/*****************************
 * BASE
 *****************************/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

html,
body {
  background-color: var(--color-beige);
  color: #000;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

h1 {
  font-size: 68px;
  line-height: 81px;
  letter-spacing: -0.04em;
}

h2 {
  font-size: 54px;
  line-height: 64px;
  letter-spacing: -0.02em;
}

h3 {
  font-size: 44px;
  line-height: 52px;
  letter-spacing: -0.01em;
}

p,
ul,
ol {
  font-size: 22px;
  font-weight: 300;
  line-height: 28px;
}

section {
  padding: 135px 0;
}

img {
  max-width: 100%;
}

label {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: block;

  span {
    color: #c11bc1;
  }
}

input,
select {
  display: block;
  width: 100%;
  background: rgba(249, 247, 238, 0.5);
  border: 1px solid #d9d8d6;
  box-sizing: border-box;
  border-radius: 8px;
  height: 48px;
  padding: 8px;
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 16px;
}

input:focus {
  outline: none;
  box-shadow: 0 0 0 1px var(--color-pink);
}

@media screen and (max-width: 639px) {
  h1 {
    font-size: 44px;
    line-height: 52px;
  }

  h2 {
    font-size: 28px;
    line-height: 36px;
  }

  h3 {
    font-size: 22px;
    line-height: 28px;
  }

  h4 {
    font-size: 22px;
    line-height: 28px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }

  section {
    padding: 64px 0;
  }
}
